// Small component
//

small, .small {
  font-size: get-rfs(text--2);
  font-weight: get-fontweight(normal);
}

.form-group .label, .form-group label {
  small, .small {
    font-size: get-rfs(text--2);
  }
}
