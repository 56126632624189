// Colors definitions
//

@use 'sass:map';

$atk-colors: (
  primary: (
    050: #e6a4ba,
    100: #ee85a9,
    200: #ee578b,
    300: #f03474,
    400: #f0135e,
    500: #de004b,
    600: #cc0246,
    700: #b7003e,
    800: #9b0034,
    900: #730027
  ),
  secondary: (
    050: #dee8e6,
    100: #bdded9,
    200: #acdcd4,
    300: #8ac7bd,
    400: #86baae,
    500: #80b2a6,
    600: #78a69b,
    700: #6f9c91,
    800: #628b82,
    900: #47615b
  ),
  tertiary: (
    050: #ecedfd,
    100: #dee0f9,
    200: #c0c3eb,
    300: #adb0d9,
    400: #b3a8f5,
    500: #a298dd,
    600: #968dce,
    700: #8c83c2,
    800: #7f77ac,
    900: #6b6595
  ),
  shade-white: (
    0: #fff
  ),
  shade-black: (
    0: #000,
    025: #fafafc,
    050: #b8b8b8,
    100: #606060,
    200: #454545,
    300: #383838,
    400: #323232,
    500: #2d2d2d,
    600: #252525,
    700: #1a1a1a,
    800: #111
  ),
  price: (
    500: #7fba00,
    700: #006646
  )
) !default;

$atk-subscription-colors: (
  subscription: (
    050: #c7e1e8,
    100: #9ed0dd,
    200: #70c0d5,
    300: #40a5bF,
    400: #2099b9,
    500: #0d90b3,
    600: #0d7e9c,
    700: #077491,
    800: #065f77,
    900: #06404f
  )
) !default;

$atk-colors: map.merge($atk-colors, $atk-subscription-colors);

// Colors variables
:root {
  @each $atk-color-key, $atk-colors in $atk-colors {
    @each $atk-color-variant, $atk-color in $atk-colors {
      --#{$class-prefix}color-#{$atk-color-key}-#{$atk-color-variant}: #{$atk-color};
    }
  }
}

@each $atk-color-key, $atk-colors in $atk-colors {
  @each $atk-color-variant, $atk-color in $atk-colors {
    // Text
    @include prefixed-class(text-color-#{$atk-color-key}-#{$atk-color-variant}) {
      color: #{$atk-color};
    }

    // BackgroundColor
    @include prefixed-class(bg-color-#{$atk-color-key}-#{$atk-color-variant}) {
      background-color: #{$atk-color};
    }

    // Border
    @include prefixed-class(border-color-#{$atk-color-key}-#{$atk-color-variant}) {
      border-color: #{$atk-color};
    }
  }
}

// Add some utilities classes
@include prefixed-class(bg-none) {
  background: none;
}
