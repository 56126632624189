// Typographies stacks definitions
//

@use 'sass:map';

$atk-font-families: (
  base: (-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'),
  heading: "'Helvetica', sans-serif"
) !default;

$atk-font-weights: (
  light: 300,
  regular: 400,
  bold: 700
) !default;

$atk-rfs: (
  text--3: clamp(9px, calc(9px + 0.03vw), 12px),
  text--2: clamp(12px, calc(12px + 0.03vw), 13px),
  text--1: clamp(15px, calc(15px + 0.11vw), 16px),
  text-0: clamp(18px, calc(18px + 0.22vw), 20px),
  text-1: clamp(21px, calc(21px + 0.37vw), 25px),
  text-2: clamp(26px, calc(26px + 0.58vw), 31px),
  text-3: clamp(31px, calc(31px + 0.87vw), 39px),
  text-4: clamp(37px, calc(37px + 1.25vw), 48px),
  text-5: clamp(45px, calc(45px + 1.77vw), 61px)
) !default;

$atk-lines-height: (
  base: 1.5,
  heading: 1.2
) !default;

// Font family
@each $atk-font-family-key, $atk-font-family in $atk-font-families {
  @include prefixed-class(font-#{$atk-font-family-key}) {
    font-family: #{$atk-font-family};
  }
}

// Font weight
@each $atk-font-weight-key, $atk-font-weight in $atk-font-weights {
  @include prefixed-class(font-weight-#{$atk-font-weight-key}) {
    font-weight: #{$atk-font-weight};
  }
}

// Responsive font size
@each $atk-rfs-key, $atk-rfs-value in $atk-rfs {
  @include prefixed-class(rfs-#{$atk-rfs-key}) {
    font-size: get-rfs($atk-rfs-key);
  }
}

// Line height
@each $atk-line-height-key, $atk-line-height in $atk-lines-height {
  @include prefixed-class(lh-#{$atk-line-height-key}) {
    line-height: get-lineheight($atk-line-height-key);
  }
}
