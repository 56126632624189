// Animation mixins
//

@mixin animation($animation-name, $animation-duration, $animation-timing-function, $animation-delay: null, $animation-iteration-count: null, $animation-direction: null) {
  -webkit-animation: $animation-name $animation-duration $animation-timing-function $animation-delay $animation-iteration-count $animation-direction;
  -moz-animation: $animation-name $animation-duration $animation-timing-function $animation-delay $animation-iteration-count $animation-direction;
  -ms-animation: $animation-name $animation-duration $animation-timing-function $animation-delay $animation-iteration-count $animation-direction;
  -o-animation: $animation-name $animation-duration $animation-timing-function $animation-delay $animation-iteration-count $animation-direction;
  animation: $animation-name $animation-duration $animation-timing-function $animation-delay $animation-iteration-count $animation-direction;
}

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name}{
    @content;
  }
}
