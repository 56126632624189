// Range component
//

@use 'sass:map';

$atk-range-types: (
  thin: (
    height: 1px,
    background-color-slider-runnable-track: get-color(shade-black, 0),
    background-color-slider-thumb: get-color(shade-white, 0),
    margin-top: -7.2px
  ),
) !default;

@each $atk-range-type in map.keys($atk-range-types) {
  @include prefixed-class('custom-range-#{$atk-range-type}') {
    &::-moz-range-track {
      height: get-rangeproperty(height, $atk-range-type);
    }

    &::-webkit-slider-runnable-track {
      height: get-rangeproperty(height, $atk-range-type);
      background-color: get-rangeproperty(background-color-slider-runnable-track, $atk-range-type);
    }

    &::-webkit-slider-thumb {
      background-color: get-rangeproperty(background-color-slider-thumb, $atk-range-type);
      margin-top: get-rangeproperty(margin-top, $atk-range-type);
    }

    &::-ms-thumb,
    &::-moz-range-thumb {
      margin-top: get-rangeproperty(margin-top, $atk-range-type);
    }
  }
}
