// Animation definitions
//

@include keyframes(shake) {
  0% {
    @include translate-x(0);
  }
  10% {
    @include translate-x(-5px);
  }
  20% {
    @include translate-x(5px);
  }
  30% {
    @include translate-x(-5px);
  }
  40% {
    @include translate-x(5px);
  }
  50% {
    @include translate-x(-5px);
  }
  60% {
    @include translate-x(5px);
  }
  70% {
    @include translate-x(-5px);
  }
  80% {
    @include translate-x(5px);
  }
  90% {
    @include translate-x(-5px);
  }
  100% {
    @include translate-x(0);
  }
}
