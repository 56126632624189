// Mixin Opacity
//

@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: calc($opacity * 100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+$opacity-ie+")";
  filter: alpha(opacity=$opacity-ie);
}
