// Icon mixin
//

@mixin icon($icon, $type: solid) {
  @extend %fa-icon;
  @font-face {
    font-display: block;
  }

  @if ($type == 'regular') {
    @extend .far;
  } @else if ($type == 'solid') {
    @extend .fas;
  } @else if ($type == 'brands') {
    @extend .fab;
  }

  content: get-icon($icon);
}
