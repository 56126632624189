// Mixin Transition
//

@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: #{$transition-delay};
  transition-delay: #{$transition-delay};
}

@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: #{$transition-duration};
  transition-duration: #{$transition-duration};
}

@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
  -moz-transition: -moz-transform $transition;
  -o-transition: -o-transform $transition;
  transition: transform #{$transition};
}

@mixin transition-timing-function($value) {
  -webkit-transition-timing-function: $value;
  -moz-transition-timing-function: $value;
  -o-transition-timing-function: $value;
  transition-timing-function: $value;
}
