// Story animations
//

@keyframes stories-slider-in {
  0% {
    @include transform(translate3d(0, 0, 0) scale(0.5));
    @include opacity(0);
  }
  50% {
    @include transform(translate3d(0, 0, 0) scale(1.05));
    @include opacity(1);
  }
  100% {
    @include transform(translate3d(0, 0, 0) scale(1));
    @include opacity(1);
  }
}

@keyframes stories-slider-out {
  0% {
    @include transform(translate3d(0, 0, 0) scale(1));
    @include opacity(1);
  }
  50% {
    @include transform(translate3d(0, 0, 0) scale(0.5));
    @include opacity(0);
  }
  100% {
    @include transform(translate3d(0, 0, 0) scale(0.5));
    @include opacity(0);
  }
}
