// Body tag
//

body {
  background-color: get-color(shade-black, 025);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:not(#checkout) {
    #wrapper {
      margin-top: get-spacer();
    }
  }
}
