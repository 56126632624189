// Mixin Box Shadow
//

@use 'sass:list';

@mixin box-shadow($shadow...) {
  $result: ();
  @if length($shadow) == 1 {
    $result: $shadow;
  } @else {
    @for $i from 1 through length($shadow) {
      @if list.nth($shadow, $i) != "none" {
        $result: list.append($result, list.nth($shadow, $i), "comma");
      }
    }
  }
  @if length($result) > 0 {
    box-shadow: $result;
  }
}
