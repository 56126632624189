// Story Mixins
//

@use 'sass:map';

@mixin story-size($size) {
  $size: get-storyslidersize($size);

  max-width: #{map.get($size, max-width)}px;
  max-height: #{map.get($size, max-height)}px;
}
