// Z-indexes definitions
//

$atk-z-indexes: (
  dropdown: 1000,
  sticky: 1020,
  fixed: 1030,
  modal-backdrop: 1040,
  modal: 1050,
  popover: 1060,
  tooltip: 1070
) !default;

@each $atk-z-index-key, $atk-z-index in $atk-z-indexes {
  @include prefixed-class(z-index-#{$atk-z-index-key}) {
    z-index: #{$atk-z-index};
  }
}
