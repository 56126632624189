// Input-file component
//

@use 'sass:map';

$atk-input-sizes: (
  lg: (
    height: $input-height-lg,
    padding-y: $custom-select-padding-y-lg,
    padding-x: $custom-select-padding-x-lg,
    font-size: $custom-select-font-size-lg
  ),
  sm: (
    height: $input-height-sm,
    padding-y: $custom-select-padding-y-sm,
    padding-x: $custom-select-padding-x-sm,
    font-size: $custom-select-font-size-sm
  )
) !default;

@each $atk-input-size in map.keys($atk-input-sizes) {
  @include prefixed-class('custom-file-#{$atk-input-size}') {
    height: get-inputfileproperty(height, $atk-input-size);

    .custom-file-input {
      height: get-inputfileproperty(height, $atk-input-size);
    }

    .custom-file-label {
      height: get-inputfileproperty(height, $atk-input-size);
      font-size: get-inputfileproperty(font-size, $atk-input-size);

      &::after {
        height: get-inputfileproperty(height, $atk-input-size);
        padding: get-inputfileproperty(padding-y, $atk-input-size) get-inputfileproperty(padding-x, $atk-input-size);
      }
    }
  }
}
