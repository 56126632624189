// Radius definitions
//

$atk-radius-base: 4px !default;

$atk-radius: (
  xs: $atk-radius-base,
  sm: $atk-radius-base * 2,
  md: $atk-radius-base * 3,
  normal: $atk-radius-base * 4,
  lg: $atk-radius-base * 5,
  xl: $atk-radius-base * 6,
  xxl: $atk-radius-base * 7.5
) !default;

@each $atk-radius-key, $atk-radius in $atk-radius {
  @include prefixed-class(radius-#{$atk-radius-key}) {
    @include border-radius($atk-radius);
  }
}

// add somes utilities classes
@include prefixed-class(radius-top-right-0) {
  border-top-right-radius: 0;
}

@include prefixed-class(radius-top-left-0) {
  border-top-left-radius: 0;
}

@include prefixed-class(radius-bottom-right-0) {
  border-bottom-right-radius: 0;
}

@include prefixed-class(radius-bottom-left-0) {
  border-bottom-left-radius: 0;
}
