// Checkbox-btn component
//

@include prefixed-class('checkbox-btn') {
  position: fixed;
  @include opacity(0);
  width: 0;

  + label {
    @extend .#{$class-prefix}btn !optional;
    @extend .#{$class-prefix}btn-secondary !optional;
    @extend .#{$class-prefix}btn-medium !optional;
  }

  &:checked {
    + label {
      @extend .#{$class-prefix}btn-primary !optional;

      @include hover {
        color: get-color(shade-white, 0);
      }
    }
  }
}
