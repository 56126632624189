// List component
//

$atk-list: (
  styled: (
    list-style: disc,
    padding-left: get-spacer(),
  )
);

ul {
  padding-left: 0;
  list-style: none;

  @each $name, $properties in $atk-list {
    &.list-#{$name} {
      @each $property, $value in $properties {
        #{$property}: $value;
      }
    }
  }
}
