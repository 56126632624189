// Aside modal Component
//

$atk-aside-modal-width: (
  desktop: 30vw,
  mobile: 75vw
) !default;

@include prefixed-class(modal-aside) {
  &.fade .modal-dialog {
    // À laisser pour surcharger la règle de bootstrap
    @include transform(none);
    @include transition(left .3s ease-in-out, right .3s ease-in-out, opacity .3s ease-in-out);
  }

  .modal-dialog {
    position: fixed;
    margin: auto;
    height: 100%;
    width: get-asidemodalwidth(mobile);
    @include media-breakpoint-desktop {
      width: get-asidemodalwidth(desktop);
    }
    @include transition(left .3s ease-in-out, right .3s ease-in-out, opacity .3s ease-in-out);

    .modal-content {
      height: 100%;
      overflow-y: auto;
    }
  }
}

// Right
@include prefixed-class(modal-right) {
  &.show .modal-dialog {
    right: 0;
  }

  &:not(.show) .modal-dialog {
    right: - get-asidemodalwidth(mobile);
    @include media-breakpoint-desktop {
      right: - get-asidemodalwidth(desktop);
    }
  }
}

// Left
@include prefixed-class(modal-left) {
  &.show .modal-dialog {
    left: 0;
  }

  &:not(.show) .modal-dialog {
    left: - get-asidemodalwidth(mobile);
  }
}
