// Mixin Button Size
//

@use 'sass:map';

@mixin button-size($atk-buttons-size){
  font-size: map.get($atk-buttons-size, font-size);
  padding-block: map.get($atk-buttons-size, padding-block);
  padding-inline: map.get($atk-buttons-size, padding-inline);
  block-size: map.get($atk-buttons-size, block-size);
  letter-spacing: map.get($atk-buttons-size, letter-spacing);
}
