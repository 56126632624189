// Switch component
//

@use 'sass:map';

$atk-switch-sizes: (
  lg: (
    gutters: 2px,
    width: 40px,
    height: 20px,
    switch-size: 20px
  ),
  xl: (
    gutters: 3px,
    width: 50px,
    height: 25px,
    switch-size: 25px
  )
) !default;

@each $atk-switch-size in map.keys($atk-switch-sizes) {
  @include prefixed-class('custom-switch-#{$atk-switch-size}') {
    position: relative;
    padding-left: get-switchproperty(width, $atk-switch-size) - get-switchproperty(gutters, $atk-switch-size)*2;
    height: get-switchproperty(height, $atk-switch-size) + get-switchproperty(gutters, $atk-switch-size)*2;

    .custom-control-label {
      position: relative;
      @include flexbox;
      @include align-items(center);
      height: 100%;
      padding-left: get-spacer();

      &::before {
        top: 0;
        left: -(get-switchproperty(width, $atk-switch-size) - get-switchproperty(gutters, $atk-switch-size)*2);
        width: get-switchproperty(width, $atk-switch-size) + get-switchproperty(gutters, $atk-switch-size)*2;
        height: get-switchproperty(height, $atk-switch-size) + get-switchproperty(gutters, $atk-switch-size)*2;
        border-radius: get-switchproperty(height, $atk-switch-size) + get-switchproperty(gutters, $atk-switch-size)*2;
      }

      &::after {
        top: get-switchproperty(gutters, $atk-switch-size);
        left: -(get-switchproperty(width, $atk-switch-size) - get-switchproperty(gutters, $atk-switch-size)*3);
        width: get-switchproperty(switch-size, $atk-switch-size);
        height: get-switchproperty(switch-size, $atk-switch-size);
        border-radius: get-switchproperty(switch-size, $atk-switch-size);
      }
    }

    .custom-control-input:checked ~ .custom-control-label {
      &::after {
        transform: translateX(calc(get-switchproperty(width, $atk-switch-size) + get-switchproperty(gutters, $atk-switch-size)*2) - calc((get-switchproperty(switch-size, $atk-switch-size) + get-switchproperty(gutters, $atk-switch-size)*2)));
      }
    }
  }
}
