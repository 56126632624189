// Badges component
//

@use 'sass:map';

$atk-badges: (
  dog: get-color(secondary, 400),
  cat: get-color(tertiary, 400)
) !default;

@include prefixed-class(badge) {
  // override badge font-size for mobile
  @include media-breakpoint-mobile {
    font-size: get-rfs(text--3);
  }

  @each $variant, $bg-color in $atk-badges {
    &-#{$variant} {
      background-color: #{$bg-color};
      color: get-color(shade-white, 0);
    }
  }

  &-bright {
    color: get-color(shade-white, 0);
  }
}
