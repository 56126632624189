// Step card component
//

@include prefixed-class(step-card) {
  border: none;
  background: none;

  > .card-header {
    @extend .border;
    @extend .border-bottom-0;
  }

  @include prefixed-class(step-card-content) {
    @extend .p-0;

    @include prefixed-class(step-card-child) {
      &:not(:first-of-type) {
        @extend .mt-3;
      }
    }

    @include prefixed-class(step-card-child) {
      &:first-child {
        @extend .#{$class-prefix}radius-top-right-0;
        @extend .#{$class-prefix}radius-top-left-0;
      }
    }
  }
}

// step-card in collapse component
@include prefixed-class(step-card-collapse) {
  &:not(.js-current-step) {
    @extend .border-bottom;
  }

  &.js-current-step {
    border: none;
    background: none;

    > .card-header {
      @extend .border;
      @extend .border-bottom-0;
    }

    @include prefixed-class(step-card-content) {
      @extend .p-0;

      @include prefixed-class(step-card-child) {
        &:not(:first-of-type) {
          @extend .mt-3;
        }
      }

      @include prefixed-class(step-card-child) {
        &:first-child {
          @extend .#{$class-prefix}radius-top-right-0;
          @extend .#{$class-prefix}radius-top-left-0;
        }
      }
    }
  }

  // add checkout variant
  &.#{$class-prefix}checkout-step {
    margin-bottom: get-spacer();
    @include transition(box-shadow .3s ease-in-out);

    &.-current > .card-header {
      background-color: get-color(shade-white, 0);
    }

    &.-reachable > .card-header {
      cursor: pointer;

      @include hover {
        @include box-shadow(get-shadow(03));
      }
    }
  }
}
