// Mixin Button Variants
//

@use 'sass:map';

@mixin button-variant($atk-button-variant) {
  background-color: map.get($atk-button-variant, background-color);
  color: map.get($atk-button-variant, color);
  fill: map.get($atk-button-variant, color);

  @if map.get($atk-button-variant, text-transform) != null {
    text-transform: map.get($atk-button-variant, text-transform);
  }

  @include not-disabled {
    outline: 0;

    @include hover {
      background-color: map.get($atk-button-variant, hover-background-color);
    }

    @include active {
      background-color: map.get($atk-button-variant, active-background-color);
    }

    @include focus-visible {
      @include box-shadow(map.get($atk-button-variant, focus-visible-box-shadow));
    }
  }
}
