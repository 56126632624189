// Modal No Background component
//

@include prefixed-class(modal-no-bg) {
  .modal-content {
    @include box-shadow(none);
    border: none;
    background-color: transparent;
  }
}
