// Story component
//

@use 'sass:map';

@import 'functions';
@import 'mixins';
@import 'story-animations';

$atk-z-indexes-slider: (
  slider-pagination: 50,
  slider-content: 100,
  slider-navigation: 200,
  slider-user: 300,
  slider-actions: 300,
  slider-shadows: 300
) !default;

$atk-z-indexes: map.merge($atk-z-indexes, $atk-z-indexes-slider);

$atk-story-sizes: (
  xxxl:(
    max-width: 1080,
    max-height: 1920
  ),
  xxl: (
    max-width: 720,
    max-height: 1280
  ),
  xl: (
    max-width: 600,
    max-height: 1067
  ),
  lg: (
    max-width: 480,
    max-height:853
  ),
  md: (
    max-width: 360,
    max-height: 640
  ),
  sm: (
    max-width: 270,
    max-height: 480
  ),
  s: (
    max-width: 240,
    max-height: 426
  ),
  xs: (
    max-width: 180,
    max-height: 320
  ),
  xxs: (
    max-width: 144,
    max-height: 256
  )
) !default;

.swiper {
  @include story-size(md);
}

.stories-slider {
  position: fixed;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: get-zindex(modal);
  @include transform(translate3d(0, 0, 0) scale(.5));
  background: rgba(0, 0, 0, .6);
  @include opacity(0);

  &:not(&-in) {
    pointer-events: none;

    * {
      pointer-events: none !important;
    }
  }

  &-in {
    pointer-events: auto;
    @include animation(stories-slider-in, 400ms, forwards);
    @include transform(translate3d(0, 0, 0) scale(1));
    @include opacity(1);
  }

  &-out {
    pointer-events: none;
    @include animation(stories-slider-out, 400ms, forwards !important);
  }

  > .swiper > .swiper-wrapper {
    > .swiper-slide:not(.swiper-slide-next, .swiper-slide-prev, .swiper-slide-active) {
      @include opacity(0);
    }

    > .swiper-slide-next + .swiper-slide {
      @include opacity(1);
    }
  }

  .swiper {
    height: 100%;
    width: 100%;

    .swiper .swiper-slide {
      @include border-radius(8px);
      background-color: get-color(shade-black, 0);
      overflow: hidden;
    }

    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      z-index: get-zindex(slider-shadows) !important;
    }

    .swiper-slide-shadow-left {
      @include linear-gradient(to left, get-color(shade-black, 0), rgba(0, 0, 0, .5));
    }

    .swiper-slide-shadow-right {
      @include linear-gradient(to right, get-color(shade-black, 0), rgba(0, 0, 0, .5));
    }
  }

  &.stories-slider-perspective {
    .swiper .swiper,
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right {
      @include translate-3d(0, 0, calc(-1 * var(--swiper-cube-translate-z)));
      @include transform-origin(0, 0, calc(-1 * var(--swiper-cube-translate-z)));
    }
  }
}

.stories-slider-button {
  position: absolute;
  top: 0;
  height: 100%;
  @include user-select(none);
  z-index: get-zindex(slider-navigation);

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    @include transition-duration(200ms);
  }

  &:active:before {
    @include opacity(.25);
  }

  &-prev {
    left: 0;
    width: 30%;

    &:before {
      @include linear-gradient(to right, get-color(shade-black, 0), rgba(0, 0, 0, 0))
    }
  }

  &-next {
    right: 0;
    width: 70%;

    &:before {
      @include linear-gradient(to left, get-color(shade-black, 0), rgba(0, 0, 0, 0));
    }
  }
}

.stories-slider-pagination {
  position: absolute;
  @include flexbox;
  @include justify-content(space-between);
  left: 8px;
  right: 8px;
  top: 8px;
  z-index: get-zindex(slider-pagination);
  pointer-events: none;
  @include translate-3d(0, 0, 0);

  &-bullet {
    position: relative;
    width: 100%;
    height: 2px;
    @include flex-shrink(10);
    background: rgba(255, 255, 255, .35);
    overflow: hidden;
    @include border-radius(999px);
    @include box-shadow(0 0 1px rgba(0, 0, 0, .35));

    span {
      position: absolute;
      background: get-color(shade-white, 0);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      @include border-radius(999px);
      @include translate-x(-100%);
      @include transition-timing-function(linear);
    }

    &-viewed span {
      @include translate-x(0);
      @include transition-duration(0ms !important);
    }
  }

  &-bullet + &-bullet {
    margin-left: 2px;
  }
}

a.stories-slider-user,
.stories-slider-user a {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include transition-duration(200ms);

  &:active {
    @include opacity(.55);
  }
}

.stories-slider-user {
  position: absolute;
  @include flexbox;
  @include align-items(center);
  top: 18px;
  left: 12px;
  height: 32px;
  z-index: get-zindex(slider-user);
  color: get-color(shade-white, 0);
  text-decoration: none;
  @include user-select(none);

  &-avatar, &-name {
    @include font-size(14px);
    margin-right: 12px;
    text-shadow: 0 0 2px rgba(0, 0, 0, .35);
  }

  &-avatar {
    width: 32px;
    height: 32px;
    @include border-radius(50%);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      @include border-radius(50%);
    }
  }

  &-name {
    font-weight: get-fontweight(bold);
  }
}

.stories-slider-actions {
  position: absolute;
  @include flexbox;
  @include align-items(center);
  @include justify-content(space-between);
  right: 10px;
  top: 18px;
  height: 32px;
  @include user-select(none);
  @include transition-duration(200ms);
  z-index: get-zindex(slider-actions);

  button {
    background: none;
    border: none;
    color: get-color(shade-white, 0);
  }
}

.stories-slider-close-button {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @include user-select(none);
  @include box-shadow(none);

  &:before,
  &:after {
    content: '';
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    width: 125%;
    height: 2px;
    background: get-color(shade-white, 0);
    @include border-radius(999px);
    @include box-shadow(0 0 1px rgba(0, 0, 0, .35));
  }

  &:before {
    @include transform(translate(-50%, -50%) rotate(45deg));
  }

  &:after {
    @include transform(translate(-50%, -50%) rotate(-45deg));
  }

  &:active {
    @include opacity(.55);
  }
}

.stories-slider-content {
  position: relative;
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  width: 100%;
  height: 100%;
  @include user-select(none);
  z-index: get-zindex(slider-content);

  > img,
  > video {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    object-position: center;
  }

  > img {
    object-fit: cover;
  }

  > video {
    object-fit: contain;
  }
}
