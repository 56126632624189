// Mixin utils
//

@mixin disabled() {
  &:disabled,
  &.disabled {
    @content;
  }
}

@mixin not-disabled() {
  &:not(:disabled),
  &:not(.disabled) {
    @content;
  }
}

@mixin active() {
  &:active {
    @content;
  }
}

@mixin focus-visible() {
  &:focus-visible {
    @content;
  }
}

@mixin hover-focus-active() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin empty() {
  &:empty {
    @content;
  }
}

@mixin after() {
  &:after {
    @content;
  }
}

@mixin before() {
  &:before {
    @content;
  }
}
