@use 'sass:map';

@include atkBanner('Icon');

@import 'function';
@import 'mixin';

$menu-icons: (
  search: fa-content($fa-var-magnifying-glass),
  user: fa-content($fa-var-user),
  cart: fa-content($fa-var-shopping-bag)
) !default;

$account-icons: (
  personal-datas: fa-content($fa-var-users),
  account-linked: fa-content($fa-var-share-nodes),
  sponsorship: fa-content($fa-var-share),
  pot: fa-content($fa-var-shopping-cart),
  alerts: fa-content($fa-var-bell),
  subscription: fa-content($fa-var-arrows-rotate),
  dog: fa-content($fa-var-dog),
  cat: fa-content($fa-var-cat),
  informations: map.get($menu-icons, user), // même icône que dans le menu
  addresses: fa-content($fa-var-address-book),
  orders-history: fa-content($fa-var-list),
  credits: fa-content($fa-var-credit-card),
  vouchers: fa-content($fa-var-percent)
) !default;

$footer-icons: (
  facebook: fa-content($fa-var-facebook-f),
  instagram: fa-content($fa-var-instagram),
  twitter: fa-content($fa-var-twitter),
  linkedin: fa-content($fa-var-linkedin),
  pinterest: fa-content($fa-var-pinterest),
  rss: fa-content($fa-var-rss),
  youtube: fa-content($fa-var-youtube),
  vimeo: fa-content($fa-var-vimeo),
  location: fa-content($fa-var-location-dot),
  phone: fa-content($fa-var-phone),
  contact: fa-content($fa-var-envelope)
) !default;

$checkout-icons: (
  shipping: fa-content($fa-var-truck),
  access-point: map.get($footer-icons, location) // même icône que dans le footer
) !default;

$utility-icons: (
  tooltip: fa-content($fa-var-circle-info),
  trash: fa-content($fa-var-trash),
  up: fa-content($fa-var-chevron-up),
  right: fa-content($fa-var-chevron-right),
  down: fa-content($fa-var-chevron-down),
  left: fa-content($fa-var-chevron-left),
  close: fa-content($fa-var-xmark),
  plus: fa-content($fa-var-plus),
  valid: fa-content($fa-var-check),
  volume-muted: fa-content($fa-var-volume-xmark),
  volume-high: fa-content($fa-var-volume-high),
  play: fa-content($fa-var-play),
  pause: fa-content($fa-var-pause),
  bowl-food: fa-content($fa-var-bowl-food)
) !default;

$atk-icons: map.merge($menu-icons, $account-icons);
$atk-icons: map.merge($atk-icons, $footer-icons);
$atk-icons: map.merge($atk-icons, $checkout-icons);
$atk-icons: map.merge($atk-icons, $utility-icons);

$font-awesome-styles: (
  solid, brands, regular
) !default;

// Classes
@each $icon in map.keys($atk-icons) {
  @include prefixed-class('icon-#{$icon}') {
    &:before {
      @include icon($icon)
    }
  }

  @each $style in $font-awesome-styles {
    @if $style != solid {
      @include prefixed-class('icon-#{$style}-#{$icon}') {
        &:before {
          @include icon($icon, $style)
        }
      }
    }
  }
}

$dynamical-icons: (
  rotate: (
    up: up,
    right: right,
    down: down,
    left: left
  ),
  replace: (
    search: close,
    volume-muted: volume-high,
    play: pause
  )
) !default;

@each $animation, $atk-icons in $dynamical-icons {
  @each $key, $icon in $atk-icons {
    @include prefixed-class(icon-#{$key}-toggle) {
      @extend .#{$class-prefix}icon-#{$key};

      &:before {
        @include transition(transform .3s ease-in-out);
      }
    }

    @if $animation == rotate {
      @include prefixed-class(icon-#{$key}-toggle) {
        &:before {
          @include transition(transform .3s ease-in-out);
        }
      }

      // Collapse only
      [data-toggle="collapse"] {
        &[aria-expanded="true"] {
          @include prefixed-class(icon-#{$key}-toggle) {
            &:before {
              @include rotate(180);
            }
          }
        }
      }
    }

    @if $animation == replace {
      // Open only
      .open {
        @include prefixed-class(icon-#{$key}-toggle) {
          &:before {
            @include icon($icon);
          }
        }

        @each $style in $font-awesome-styles {
          @if $style != solid {
            @include prefixed-class(icon-#{$style}-#{$icon}-toggle) {
              &:before {
                @include icon($icon, $style)
              }
            }
          }
        }
      }
    }
  }
}
