// Object-fit definitions
//

$atk-objects-fit: (
  cover,
  contain,
  fill,
  inherit,
  initial,
  none,
  scale-down,
  unset
) !default;

@each $atk-object-fit in $atk-objects-fit {
  @include prefixed-class(object-fit-#{$atk-object-fit}) {
    object-fit: #{$atk-object-fit};
    width: 100%;
    height: 100%;
  }
}
