// Radio component
//

@include prefixed-class('radio-btn') {
  position: fixed;
  @include opacity(0);
  width: 0;

  + label {
    @extend .#{$class-prefix}btn !optional;
  }

  &:not(&-dog):not(&-cat) + label {
    @extend .#{$class-prefix}btn-secondary !optional;
  }

  &:not(&-sm) + label {
    @extend .#{$class-prefix}btn-medium !optional;
  }

  &-sm + label {
    @extend .#{$class-prefix}btn-small !optional;
  }

  &:not(&-dog):not(&-cat) {
    &:checked + label {
      @extend .#{$class-prefix}btn-primary !optional;
    }
  }

  @if $allowed-dog == true {
    &-dog + label {
      font-style: italic;
      @extend .#{$class-prefix}btn-dog-secondary !optional;
    }
  }

  @if $allowed-cat == true {
    &-cat + label {
      font-style: italic;
      @extend .#{$class-prefix}btn-cat-secondary !optional;
    }
  }

  @if $allowed-dog == true {
    &-dog:checked + label {
      color: get-color(shade-white, 0);
      text-transform: none;
      background-color: get-color(secondary, 900);
      border-color: get-color(secondary, 900);
      @extend .#{$class-prefix}btn-dog-primary !optional;
    }
  }

  @if $allowed-cat == true {
    &-cat:checked + label {
      color: get-color(shade-white, 0);
      text-transform: none;
      background-color: get-color(tertiary, 900);
      border-color: get-color(tertiary, 900);
      @extend .#{$class-prefix}btn-cat-primary !optional;
    }
  }
}
