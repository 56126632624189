// Tab fill component
//

// @TODO: désactiver l'onglet s'il est vide

@include prefixed-class(tab-fill) {
  column-gap: $font-size-base;
  row-gap: $font-size-base;
  @extend .mb-3;

  .nav-item {
    @include flex(1);

    .nav-link {
      background-color: transparent;
      border-width: rem($input-border-width);
      border-style: solid;
      border-color: $input-border-color;
      color: inherit;

      &.active {
        border-color: get-color();
        color: inherit;
      }

      @include disabled {
        cursor: not-allowed;
        @include opacity(get-opacity(disabled));
      }

      i:after {
        content: '';
        position: relative;
        margin-right: rem(5px);
      }
    }
  }

  // without class variant
  &:not(&-lg) {
    .nav-link {
      @include font-size($input-font-size);
      height: $input-height;
    }
  }

  &-lg {
    .nav-link {
      @include font-size($input-font-size-lg);
      height: $input-height-lg;
    }
  }

  &-sm {
    .nav-link {
      @include font-size($input-font-size-sm);
      height: $input-height-sm;
    }
  }
}
