// Buttons component
//

@use 'sass:map';

@include atkBanner('Buttons');

// Button variants
@import '_variants/size';
@import '_variants/default';
@import '_variants/subscription';
@import '_variants/dog';
@import '_variants/cat';

// Button mixins
@import '_mixin';

@include prefixed-class(btn) {
  position: relative;
  @include flexbox;
  @include flex-direction(row);
  @include justify-content(center);
  @include align-items(center);
  @include border-radius(get-radius(xxl));
  padding-block: rem(8px);
  padding-inline: rem(15px);
  block-size: rem(42px);
  inline-size: max-content;
  line-height: get-lineheight(base);
  font-family: get-fontfamily(base), sans-serif;
  font-weight: get-fontweight(regular);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include transition(color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out);

  @include not-disabled {
    @include hover {
      cursor: pointer;
    }
  }

  @include disabled {
    cursor: not-allowed;
    opacity: get-opacity(disabled);

    @include active {
      @include animation(shake, 0.5s, ease-in-out, 0s, 2, alternate);
      @include transform-origin(center);
    }
  }
}

// Default buttons
@include prefixed-class(btn-primary) {
  @include button-variant(map.get($atk-button-variants, primary));
  border: rem(1px) solid get-color(primary, 500);
}

@include prefixed-class(btn-secondary) {
  @include button-variant(map.get($atk-button-variants, secondary));
  border: rem(1px) solid get-color(primary, 500);

  @include hover {
    color: get-color(primary, 400);
    fill: get-color(primary, 400);
    border-color: get-color(primary, 400);
  }
}

@include prefixed-class(btn-tertiary) {
  @include button-variant(map.get($atk-button-variants, tertiary));
  text-decoration: underline;
  background: none;
  border: none;

  @include hover {
    fill: get-color(primary, 400);
    color: get-color(primary, 400);
  }
}

// Subscriptions buttons
@include prefixed-class(btn-subscription-primary) {
  @include button-variant(map.get($atk-buttons-subscriptions, subscription-primary));
  border: rem(1px) solid get-color(subscription, 500);
}

@include prefixed-class(btn-subscription-secondary) {
  @include button-variant(map.get($atk-buttons-subscriptions, subscription-secondary));
  border: rem(1px) solid get-color(subscription, 500);

  @include hover {
    color: get-color(subscription, 400);
    fill: get-color(subscription, 400);
    border-color: get-color(subscription, 400);
  }
}

@include prefixed-class(btn-subscription-tertiary) {
  @include button-variant(map.get($atk-buttons-subscriptions, subscription-tertiary));
  text-decoration: underline;
  background: none;
  border: none;

  @include hover {
    fill: get-color(subscription, 400);
    color: get-color(subscription, 400);
  }
}

// Dog buttons
@include prefixed-class(btn-dog-primary) {
  @include button-variant(map.get($atk-buttons-dog, dog-primary));
  border: rem(1px) solid get-color(secondary, 500);
}

@include prefixed-class(btn-dog-secondary) {
  @include button-variant(map.get($atk-buttons-dog, dog-secondary));
  border: rem(1px) solid get-color(secondary, 500);

  @include hover {
    color: get-color(secondary, 400);
    fill: get-color(secondary, 400);
    border-color: get-color(secondary, 400);
  }
}

@include prefixed-class(btn-dog-tertiary) {
  @include button-variant(map.get($atk-buttons-dog, dog-tertiary));
  text-decoration: underline;
  background: none;
  border: none;

  @include hover {
    fill: get-color(secondary, 400);
    color: get-color(secondary, 400);
  }
}

// Cat buttons
@include prefixed-class(btn-cat-primary) {
  @include button-variant(map.get($atk-buttons-cat, cat-primary));
  border: rem(1px) solid get-color(tertiary, 500);
}

@include prefixed-class(btn-cat-secondary) {
  @include button-variant(map.get($atk-buttons-cat, cat-secondary));
  border: rem(1px) solid get-color(tertiary, 500);

  @include hover {
    color: get-color(tertiary, 400);
    fill: get-color(tertiary, 400);
    border-color: get-color(tertiary, 400);
  }
}

@include prefixed-class(btn-cat-tertiary) {
  @include button-variant(map.get($atk-buttons-cat, cat-tertiary));
  text-decoration: underline;
  background: none;
  border: none;

  @include hover {
    fill: get-color(tertiary, 400);
    color: get-color(tertiary, 400);
  }
}

@include prefixed-class(btn-small) {
  @include button-size(map.get($atk-button-sizes, small));
}

@include prefixed-class(btn-medium) {
  @include button-size(map.get($atk-button-sizes, medium));
}

@include prefixed-class(btn-large) {
  @include button-size(map.get($atk-button-sizes, large));
}
