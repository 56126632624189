// Dl component
//

dl {
  @include flexbox;
  @include flex-wrap(wrap);
  @include align-items(flex-start);

  dt, dd {
    @include flex(1, 0, 40%);
    padding-bottom: get-spacer(xs);
    word-break: break-word;
    border-bottom: 1px solid $card-border-color;
  }

  dt {
    font-weight: get-fontweight(bold);
  }

  dd {
    font-weight: get-fontweight(normal);
  }
}
