// Input-group component
//

@include prefixed-class(input-group-radio) {
  &-sm {
    @extend .#{$class-prefix}btn-small !optional;

    .form-control {
      min-width: 0;
    }
  }

  &-dog {
    @extend .#{$class-prefix}radio-btn-dog !optional;

    .input-group-text,
    .form-control {
      color: get-color(secondary, 900);
    }
  }

  &-cat {
    @extend .#{$class-prefix}radio-btn-cat !optional;

    .input-group-text,
    .form-control {
      color: get-color(tertiary, 900);
    }
  }

  .input-group-text,
  .form-control {
    border-color: $white;
    font-style: italic;
    background: none;
  }

  .input-group-text {
    @include border-right-radius(30px);
  }

  .form-control {
    @include border-left-radius(30px);
    @extend .#{$class-prefix}btn-medium !optional;
  }
}
