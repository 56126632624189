// utils classes
//

.text-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}
