// Touchspin component
//

.bootstrap-touchspin {
  > input {
    max-width: rem(60px);
    text-align: center;
    border: none;
    font-weight: get-fontweight(bold);
    @include box-shadow(none);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
