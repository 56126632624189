// Mixin Transform
//

@mixin transform($transforms...) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

@mixin transform-origin($origin...) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin scale($scale) {
  @include transform(scale($scale));
}

@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin translate-x($x) {
  @include transform(translateX($x));
}

@mixin translate-y($y) {
  @include transform(translateY($y));
}

@mixin translate-3d($x, $y, $z) {
  @include transform(translate3d($x, $y, $z));
}

@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}
